/* Custom CSS */
.nav-link {
    position: relative;
    color: #ccc;
    text-decoration: none;
    padding-bottom: 5px;
  }
  
  .nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: transparent;
    transition: width 0.3s ease, left 0.3s ease;
  }
  
  .nav-link:hover::after {
    width: calc(100% - 0px); /* Adjust the width as needed */
    left: 0px; /* Adjust the left position to start from the middle */
    background-color: #ff69b4;
  }
  
  /* Product page */
  /* Add this CSS to your stylesheet */
.button-border {
  position: relative;
  overflow: hidden;
}

.button-border::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: transparent;
  transition: width 0.6s, height 0.6s, top 0.6s, left 0.6s;
  border-style: solid;
  border-width: 0 0 0 0;
  z-index: 1;
}

.button-border:hover::before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-width: 5px 5px 5px 5px; /* Adjust the border width as needed */
  border-color: #e00d77; /* Adjust the border color as needed */
}

/* Adjust the background color and other styles for your buttons as needed */
